import { Image, ImageLoading } from '@mechhive/react';
import { Link } from '@remix-run/react';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useAccount } from '~/hooks/useAccount';
import { useIsLoggedIn } from '~/hooks/useIsLoggedIn';
import { useLanguage } from '~/hooks/useLanguage';
import { useLocalePath } from '~/hooks/useLocalePath';
import { useTranslate } from '~/hooks/useTranslate';
import { useUserAccount } from '~/hooks/useUserAccount';
import { useUserBalance } from '~/hooks/useUserBalance';
import { LANGUAGE_DISPLAY_NAME } from '~/locale';
import { ChangeLanguageModal } from '~/modals/ChangeLanguageModal';
import { Button, ButtonSize, ButtonVariant } from './Button';
import { ComponentReplacement } from './ComponentReplacement';
import { useModalStore } from './modal/ModalStore';
import { Price } from './Price';
import { HamburgerMenuItem } from './ui/HamburgerMenuItem';
import { Icon, IconName } from './ui/Icon';
import clsx from 'clsx';
import { createSkineUrl } from '~/utils/createSkineUrl';

export const HamburgerMenu = () => {
  const localePath = useLocalePath();
  const language = useLanguage();
  const userAccount = useUserAccount(); 
  const userBalance = useUserBalance(); 
  const isLoggedIn = useIsLoggedIn();
  const t = useTranslate();
  const [showMenu, setShowMenu] = useState<boolean>( false );
  const [ showBar, setShowBar ] = useState<boolean>( false );

  const menuRef = useRef<HTMLDivElement | null>( null );
  const account = useAccount();
  const changeLanguageModalStore = useModalStore();

  const currency = userAccount?.currency;
  const balance = userBalance?.availableBalance ?? 0;
  const username = userAccount?.email ?? '';
  

  const handleMenuClick = () => {
    setShowMenu( true );
  }

  useEffect( () => {
    const handleScrollEvent = () => {
      if ( window.scrollY > 2 ) {
        setShowBar( true );
      } else {
        setShowBar( false );
      }
    }
  
    handleScrollEvent();
  
    window.addEventListener( 'scroll', handleScrollEvent );
  
    return () => {
      window.removeEventListener( 'scroll', handleScrollEvent );
    }
  }, [] );

  return (
    <div className={ classNames( 
      'lg:hidden text-[#E2E8FF] text-sm fixed z-50 inset-x-0 mt-4' ,
      showBar && !showMenu ? 'bg-[rgba(12,16,46,0.50)] inset-x-4 backdrop-blur-md border border-[rgba(255,255,255,0.20)] rounded-[10px]' : '' )
    }>
      <div className='flex flex-row py-2.5 px-4 justify-between items-center'>
        <div className={ classNames( 'flex gap-4 h-8' , ` ${showMenu ? 'hidden' : ''} ` ) }>
          <button
            onClick={ handleMenuClick }
            className={ 'relative' }
          >
            <Icon
              className='font-normal text-xl'
              name={ IconName.Menu }/>
            { account?.hasCardPaymentApprovals &&
              <div className={ 'absolute w-2.5 h-2.5 bg-[#E16B45] top-0 -right-1 rounded-full' } />
            }
          </button>
          <Link
            to={ localePath( '/' ) }
            className={ classNames( 
              'flex items-center justify-center transition-all duration-200',
              showMenu === true ? 'opacity-0 pointer-events-none -translate-x-4' : 'opacity-100 pointer-events-auto translate-x-0'
            ) }>
            <svg
              width="32"
              height="25"
              viewBox="0 0 32 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={ 'fill-white' }
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.9207 0.311924H4.19595L7.76697 5.36683L17.3492 5.24804L17.8381 5.24821C19.0027 5.24865 20.1674 5.24908 21.332 5.24523C22.4271 5.24161 23.4546 5.44699 24.3728 6.03841C26.0918 7.14565 26.2685 9.51368 24.7329 10.842C23.9756 11.4972 23.0678 11.8339 22.0773 11.8893H0L3.02443 16.1942H19.494L19.8277 16.6518C20.7386 17.9011 21.6496 19.1507 22.5586 20.4013H17.7457L20.7633 24.7061H26.3241C26.3657 24.7075 26.4084 24.7078 26.4525 24.7069C26.4668 24.7066 26.481 24.7064 26.4953 24.7061H27.9237V24.691C28.4683 24.689 29.0129 24.6902 29.5576 24.6913C30.1152 24.6926 30.6727 24.6938 31.2303 24.6915C31.3679 24.6909 31.5233 24.6911 31.698 24.6913L32 24.6915C30.9923 23.3181 29.9732 21.9084 28.9498 20.4926C27.9174 19.0643 26.8803 17.6297 25.8458 16.2198C25.9487 16.1765 26.0419 16.1375 26.1286 16.1013C26.2969 16.0309 26.4407 15.9707 26.5838 15.9092C31.0605 13.9836 32.9175 8.77534 30.5126 4.69871C28.7916 1.78148 26 0.499939 22.6133 0.307807C22.001 0.273069 21.5117 0.307807 20.96 0.311924H20.9207Z"/>
            </svg>
          </Link>
        </div>
        { userAccount ? (
          <div className='flex h-full'>
            <div className={ clsx(
              'flex gap-4 items-center font-semibold',
              {
                'text-[#F57318]': userBalance?.testMode === true,
                'text-white': !userBalance?.testMode 
              }
            ) }>
              <Icon
                name={ IconName.Wallet }
                className={ 'icon-size-5' }/>
              <Price currency={ currency ?? 'usd' } >{balance}</Price>
            </div>
          </div>
        ) : (
          <Button
            className='text-sm'
            size={ ButtonSize.Small }
            to={ localePath( '/redeem' ) }
            variant={ ButtonVariant.Primary }>{t( 'BUTTON_REDEEM_LABEL' )}</Button>
        )}
      </div>
      { showMenu &&
        <>
          <div
            className={ 'fixed inset-0 bg-black/40' }
            onClick={ () => setShowMenu( false ) }/>
          <div 
            ref={ menuRef } 
            className={ 'fixed bg-black/20 left-0 top-0 h-dvh w-72 px-[30px] justify-between py-6 flex flex-col pointer-events-auto backdrop-blur-[40px] border-r-[1px] border-white/20' }>
            <div className='relative flex flex-col gap-6 overflow-y-auto overflow-x-hidden'>
              <button
                className={ ` self-end  ${showMenu ? '' : 'hidden'} ` }
                onClick={ () => setShowMenu( false ) }>
                <Icon
                  name={ IconName.Xmark }
                  className={ 'icon-size-5' }/>
              </button>
              { userAccount &&
                <div className='flex flex-col gap-[24px]'>
                  <div className={ 'flex flex-row gap-4 break-words' }>
                    <Icon
                      className='icon-size-5'
                      name={ IconName.UserAlt_1 } />
                    <span className={ 'break-words line-clamp-1' }>
                      { username }
                    </span>
                  </div>
                  <div className={ 'flex flex-row justify-between' }>
                    <div className={ clsx(
                      {
                        'text-[#F57318]': userBalance?.testMode === true,
                        'text-[#E2E8FF]': !userBalance?.testMode 
                      }
                    ) }>
                      <Price currency={ userBalance?.currency }>
                        { userBalance?.availableBalance }
                      </Price>
                    </div>
                    <Link
                      to={ account?.allowSkineAssociation ? createSkineUrl( '/rewarble', { utmMedium: 'link' } ) : localePath( '/distributors' ) }
                      target={ account?.allowSkineAssociation ? '_blank' : '' }
                      className={ 'text-xs text-[#8F8EFF] underline underline-offset-4' }>
                      {t( 'WALLET_ADD_MONEY' )}
                    </Link>
                  </div>
                  <hr className='border-t border-[#E2E8FF]/40'></hr>
                  <div className='flex flex-col gap-10'>
                    <HamburgerMenuItem
                      label={ t( 'HEADER_ACCOUNT_MENU_STORE' ) }
                      to={ localePath( '/store' ) }
                      iconName= { IconName.Shop }
                      onClick={ () => setShowMenu( false ) }/>
                    <HamburgerMenuItem
                      label={ t( 'HEADER_ACCOUNT_MENU_CARDS' ) }
                      to={ localePath( '/cards' ) }
                      iconName= { IconName.CreditCard }
                      onClick={ () => setShowMenu( false ) }/>
                    <HamburgerMenuItem
                      label={ t( 'HEADER_ACCOUNT_MENU_ORDERS' ) }
                      to={ localePath( '/orders' ) }
                      iconName= { IconName.RefreshCcwClock }
                      onClick={ () => setShowMenu( false ) }/>
                    { account?.hasCardPaymentApprovals &&
                      <HamburgerMenuItem
                        label={ t( 'HEADER_ACCOUNT_MENU_APPROVAL' ) }
                        to={ localePath( '/cards/approval' ) }
                        iconName= { IconName.ShieldCheck }
                        badge={ true }
                        onClick={ () => setShowMenu( false ) }/>
                    }
                  </div>
                  <hr className='border-t border-[#E2E8FF]/40'></hr>
                </div>
              }
              <div className='relative flex flex-col w-full gap-10'>
                <HamburgerMenuItem
                  label={ t( 'HEADER_MENU_BRANDS' ) }
                  to={ localePath( '/brands' ) }
                  iconName= { !userAccount ? IconName.GiftCard : undefined }
                  onClick={ () => setShowMenu( false ) }/>
                <HamburgerMenuItem
                  label={ t( 'HEADER_MENU_SUPPORT' ) }
                  onClick={ () => setShowMenu( false ) }
                  to={ localePath( '/support' ) }
                  iconName= { !userAccount ? IconName.MessageCircleDots : undefined }
                />
                <HamburgerMenuItem
                  label= { t( 'FOOTER_ABOUTUS' ) }
                  to={ localePath( '/about-us' ) }
                  iconName={ !userAccount ? IconName.CircleInformation : undefined }
                  onClick={ () => setShowMenu( false ) }/>
              </div>
            </div>
            <div className='relative bottom-0 py-2 w-full flex flex-col gap-4'>
              <div className='flex gap-4 self-center'>
                <button
                  className='flex gap-2.5 items-center group'
                  onClick={ () => {
                    changeLanguageModalStore.show()
                  } }>
                  <Image
                    provider='images'
                    src={ `/flags/${language.toUpperCase()}.svg` }
                    className={ 'h-auto w-6 object-contain border border-[#FFFFFF]/50 rounded-sm cursor-pointer' }
                    sizes={ [
                      { width: 24 }
                    ] }
                    alt={ `${language.toUpperCase()} flag` }
                    loading={ ImageLoading.Lazy }
                    dprVariants={ [2] }
                  />
                  <div className='group-hover:text-white'>{LANGUAGE_DISPLAY_NAME[ language ]}</div>
                </button>
              </div>
              <Button
                to={ localePath( '/redeem' ) }
                variant={ ButtonVariant.Primary }
                onClick={ () => setShowMenu( false ) }>            
                {t( 'BUTTON_REDEEM_LABEL' )}
              </Button>
              <Button
                variant={ ButtonVariant.Secondary }
                to={ localePath( isLoggedIn ? localePath( '/signout' ) : localePath( '/signin' ) ) }
                reloadDocument={ isLoggedIn ? true : false }
                onClick={ () => setShowMenu( false ) }
              >
                {isLoggedIn ? t( 'HEADER_MENU_SIGNOUT' ) : t( 'HEADER_MENU_SIGNIN' )}
              </Button>
            </div>
          </div>
        </>
      }
      <ChangeLanguageModal store={ changeLanguageModalStore } />
    </div>
  );
}