import { Link, Links, NavLink } from '@remix-run/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useIsLoggedIn } from '~/hooks/useIsLoggedIn';
import { useLocalePath } from '~/hooks/useLocalePath';
import { useTranslate } from '~/hooks/useTranslate';
import { useUserAccount } from '~/hooks/useUserAccount';
import { useUserBalance } from '~/hooks/useUserBalance';
import { Button, ButtonSize, ButtonVariant } from './Button';
import { Price } from './Price';
import { Icon, IconName } from './ui/Icon';
import { Image, ImageFormat, useInterval } from '@mechhive/react';
import { useAccount } from '~/hooks/useAccount';
import clsx from 'clsx';
import { createSkineUrl } from '~/utils/createSkineUrl';
import { Menu, MenuButton, MenuItem, MenuProvider, useMenuStore } from '@ariakit/react';

type HeaderProps = {
  menuItems?: Array<{
    to: string;
    label: string;
    reloadDocument?: boolean;
  }>;
}

export const Header = ( _: HeaderProps ) => {
  const localePath = useLocalePath();
  const t = useTranslate();
  const [ showMenu, setShowMenu ] = useState<boolean>( false );
  const isLoggedIn = useIsLoggedIn();
  const account = useAccount();
  const userAccount = useUserAccount();
  const userBalance = useUserBalance();
  const [ profileMenuOpen, setProfileMenuOpen ] = useState<boolean>( false );

  useEffect( () => {
    const handleScrollEvent = () => {
      if ( window.scrollY > 2 ) {
        setShowMenu( true );
      } else {
        setShowMenu( false );
      }
    }
  
    handleScrollEvent();
  
    window.addEventListener( 'scroll', handleScrollEvent );
  
    return () => {
      window.removeEventListener( 'scroll', handleScrollEvent );
    }
  }, [] );

  return (
    <div className={ clsx(
      'hidden lg:block fixed inset-x-0 px-4 pt-4 lg:px-0 lg:pt-[40px] z-50',
      {
        'pointer-events-none': showMenu
      }
    ) }>
      <div className={ clsx(
        'relative container mx-auto flex flex-col justify-between py-5 lg:px-4',
        {
          'pointer-events-none': showMenu
        }
      ) }>
        <div className={ 'flex flex-row items-center justify-between pb-5' }>
          <Link
            to={ userAccount ? localePath( '/store' ) : localePath( '/' ) }
            prefetch='intent'
            className={ classNames( 
              'flex items-center justify-center transition-all duration-200 hover:drop-shadow-[0px_0px_6px_rgba(255,255,255,1)]',
              showMenu === true ? 'opacity-0 pointer-events-none -translate-x-4' : 'opacity-100 pointer-events-auto translate-x-0'
            ) }>
            <Image 
              provider='images'
              src={ '/rewarble-logo.svg' }
              alt={ 'Rewarble Logo' }
              width={ 32 }
              formats={ [
                ImageFormat.WebP,
                ImageFormat.PNG
              ] }
              sizes={ [{
                width: 32
              }] }
              dprVariants={ [1,2] }
            />
          </Link>
          <div className={ classNames(
            'flex items-center justify-center transition-all duration-200',
            showMenu === true ? 'opacity-0 pointer-events-none translate-x-4' : 'opacity-100 pointer-events-auto translate-x-0'
          ) }>
            <div className={ 'flex flex-row items-center gap-14 text-sm font-semibold' }>
              { userAccount == null &&
                <NavLink
                  to={ localePath( '/brands' ) }
                  prefetch='intent'
                  end={ true }>
                  {( { isActive } ) => (
                    <span className={ classNames(
                      'hover:text-white',
                      isActive ? 'text-white' : undefined
                    ) }>
                      { t( 'HEADER_MENU_BRANDS' )}
                    </span>
                  )}
                </NavLink>
              }
              { userAccount &&
                <NavLink
                  to={ localePath( '/' ) }
                  prefetch='intent'
                  end={ true }>
                  {( { isActive } ) => (
                    <span className={ classNames(
                      'hover:text-white',
                      isActive ? 'text-white' : undefined
                    ) }>
                      { t( 'HEADER_MENU_HOME' )}
                    </span>
                  )}
                </NavLink>
              }
              <NavLink
                to={ localePath( '/support' ) }
                prefetch='intent'
                end={ true }>
                {( { isActive } ) => (
                  <span className={ classNames(
                    'hover:text-white',
                    isActive ? 'text-white' : undefined
                  ) }>
                    { t( 'HEADER_MENU_SUPPORT' )}
                  </span>
                )}
              </NavLink>
              { !isLoggedIn &&
                <NavLink
                  to={ localePath( '/signin' ) }
                  prefetch='intent'
                  end={ true }>
                  {( { isActive } ) => (
                    <span className={ classNames(
                      'hover:text-white',
                      isActive ? 'text-white' : undefined
                    ) }>
                      { t( 'HEADER_MENU_SIGNIN' )}
                    </span>
                  )}
                </NavLink>
              }
              <Button
                variant={ ButtonVariant.Primary }
                size={ ButtonSize.Small }
                className={ 'text-base' }
                to={ localePath( '/redeem' ) }
                prefetch='intent'
              >
                { t( 'BUTTON_REDEEM_LABEL' )}
              </Button>
            </div>
          </div>
        </div>
        { userAccount != null &&
          <>
            <hr className={ classNames(
              'border-[#64548D] transition-all duration-200',
              showMenu === true ? 'opacity-0 pointer-events-none scale-x-[1.032]' : 'opacity-50 pointer-events-auto scale-100'
            ) } />
            <div className={ classNames(
              'flex flex-row justify-between pt-4  transition-all duration-200 px-2',
              showMenu === true ? 'opacity-0 pointer-events-none scale-x-[1.032]' : 'opacity-100 pointer-events-auto scale-100'
            ) }>
              <div className={ 'flex flex-row gap-10 items-start' }>
                <NavLink
                  to={ localePath( '/store' ) }
                  prefetch='intent'
                  end={ true }>
                  { ( { isActive } ) => (
                    <div className={ classNames(
                      'flex flex-row gap-2 items-center',
                      isActive ? 'text-white' : 'hover:text-white'
                    ) }>
                      <Icon
                        name={ IconName.Shop }
                        className={ 'icon-size-6' } />
                      <span className={ 'text-sm font-semibold' }>{t( 'HEADER_ACCOUNT_MENU_STORE' )}</span>
                    </div>
                  )}
                </NavLink>
                <NavLink
                  to={ localePath( '/cards' ) }
                  end={ true }>
                  { ( { isActive } ) => (
                    <div className={ classNames(
                      'flex flex-row gap-2 items-center',
                      isActive ? 'text-white' : 'hover:text-white'
                    ) }>
                      <Icon
                        name={ IconName.CreditCard }
                        className={ 'icon-size-6' } />
                      <span className={ 'text-sm font-semibold' }>{t( 'HEADER_ACCOUNT_MENU_CARDS' )}</span>
                    </div>
                  )}
                </NavLink>
                <NavLink
                  to={ localePath( '/orders' ) }
                  end={ true }>
                  { ( { isActive } ) => (
                    <div className={ classNames(
                      'flex flex-row gap-2 items-center',
                      isActive ? 'text-white' : 'hover:text-white'
                    ) }>
                      <Icon
                        name={ IconName.RefreshCcwClock }
                        className={ 'icon-size-6' } />
                      <span className={ 'text-sm font-semibold' }>{t( 'HEADER_ACCOUNT_MENU_ORDERS' )}</span>
                    </div>
                  )}
                </NavLink>
                { account?.hasCardPaymentApprovals &&
                  <NavLink
                    to={ localePath( '/cards/approval' ) }
                    end={ true }>
                    { ( { isActive } ) => (
                      <div className={ classNames(
                        'flex flex-row gap-2 items-center',
                        isActive ? 'text-white' : 'hover:text-white'
                      ) }>
                        <div className={ 'relative' }>
                          <Icon
                            name={ IconName.ShieldCheck }
                            className={ 'icon-size-6' } />
                          <div className={ 'absolute w-2.5 h-2.5 bg-[#E16B45] top-0 -right-1 rounded-full' } />
                        </div>
                        <span className={ 'text-sm font-semibold' }>{t( 'HEADER_ACCOUNT_MENU_APPROVAL' )}</span>
                      </div>
                    )}
                  </NavLink>
                }
              </div>
              <div className={ 'flex flex-row gap-5 items-center' }>
                <MenuProvider
                  open={ profileMenuOpen }
                  setOpen={ ( value ) => {
                    setProfileMenuOpen( value )
                  } }>
                  <MenuButton className={ clsx(
                    'text-sm flex flex-row items-center gap-2 transition-colors duration-200 hover:text-[#E2E8FF]',
                    {
                      'text-[#E2E8FF]': profileMenuOpen
                    }
                  ) }>
                    <Icon 
                      className={ 'icon-size-6' }
                      name={ IconName.CircleUser } />
                    <div className={ 'flex flex-row gap-1 items-center' }>
                      { userAccount.email }
                      <Icon
                        name={ IconName.ChevronDown }
                        className={ clsx(
                          'icon-size-4 transition-transform duration-200',
                          {
                            '-scale-100': profileMenuOpen
                          }
                        )  }
                      />
                    </div>
                  </MenuButton>
                  <Menu
                    sameWidth
                    className={ 'mt-2 pointer-events-none absolute right-0' }>
                    <div className={ 'flex flex-col items-end pointer-events-auto' }>
                      <div className={ 'w-fit bg-[#0C102E] border border-[#7C7AFE] rounded-[10px] drop-shadow-[0px_-6.142px_15.354px_0px_rgba(255,255,255,0.20)_inset]' }>
                        <MenuItem
                          className={ 'text-sm group/menuitem cursor-pointer font-onest' }>
                          <Link
                            to={ localePath( '/signout' ) }
                            reloadDocument={ true }
                            className={ 'group-first/menuitem:pt-3.5 group-last/menuitem:pb-3.5 p-2 px-3.5  flex flex-row items-center gap-2 group-hover/menuitem:text-[#E2E8FF]' }
                            onClick={ () => {
                              setProfileMenuOpen( false );
                            } }
                          >
                            <Icon
                              name={ IconName.ArrowRightFromBracket }
                              className={ 'icon-size-5 w-5 h-5' } />
                            {t( 'HEADER_MENU_SIGNOUT' )}
                          </Link>
                        </MenuItem>
                      </div>
                    </div>
                  </Menu>
                </MenuProvider>
                <div className={ 'text-sm' }>
                  <span className={ clsx(
                    'text-sm font-semibold',
                    {
                      'text-[#F57318]': userBalance?.testMode === true,
                      'text-[#E2E8FF]': userBalance?.testMode !== true
                    }
                  ) }>
                    <Price currency={ userBalance?.currency }>
                      { userBalance?.availableBalance ?? 0 }
                    </Price>
                  </span>
                </div>
                <Link
                  to={ account?.allowSkineAssociation ? createSkineUrl( '/rewarble', { utmMedium: 'link' } ) : localePath( '/distributors' ) }
                  target={ account?.allowSkineAssociation ? '_blank' :  '' }
                  className={ 'text-sm text-[#8F8EFF] underline' }
                >
                  { t( 'WALLET_ADD_MONEY' )}
                </Link>
              </div>
            </div>
          </>
        }
      </div>
      <div className={ classNames(
        'transition-all duration-200 fixed inset-x-0 top-0 flex items-center justify-center pointer-events-none',
      ) }>
        <div className={ clsx(
          'bg-[rgba(12,16,46,0.50)] backdrop-blur-md mt-12 px-2.5 py-2 border border-[rgba(255,255,255,0.20)] rounded-[10px] w-fit',
          showMenu === true ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
        ) }>
          
          <div className={ 'flex flex-row items-center' }>
            <Link
              to={ localePath( '/' ) }
              className={ 'flex items-center justify-center hover:drop-shadow-[0px_0px_6px_rgba(255,255,255,1)] transition-all duration-200' }>
              <Image 
                provider='images'
                src={ '/rewarble-logo.svg' }
                alt={ 'Rewarble Logo' }
                width={ 32 }
                formats={ [
                  ImageFormat.WebP,
                  ImageFormat.PNG
                ] }
                sizes={ [{
                  width: 32
                }] }
                dprVariants={ [1,2] }
              />
            </Link>
            <div className={ 'flex-1 px-8' }>
              <div className={ 'flex flex-row justify-between text-sm font-semibold gap-12' }>
                { userAccount == null &&
                  <NavLink
                    to={ localePath( '/brands' ) }
                    prefetch='intent'
                    end={ true }>
                    {( { isActive } ) => (
                      <span className={ classNames(
                        'hover:text-white',
                        isActive ? 'text-white' : undefined
                      ) }>
                        { t( 'HEADER_MENU_BRANDS' )}
                      </span>
                    )}
                  </NavLink>
                }
                { userAccount &&
                  <NavLink
                    to={ localePath( '/' ) }
                    prefetch='intent'
                    end={ true }>
                    {( { isActive } ) => (
                      <span className={ classNames(
                        'hover:text-white',
                        isActive ? 'text-white' : undefined
                      ) }>
                        { t( 'HEADER_MENU_HOME' )}
                      </span>
                    )}
                  </NavLink>
                }
                { userAccount != null &&
                  <NavLink
                    to={ localePath( '/store' ) }
                    prefetch='intent'
                    end={ true }>
                    {( { isActive } ) => (
                      <span className={ classNames(
                        'hover:text-white',
                        isActive ? 'text-white' : undefined
                      ) }>
                        { t( 'HEADER_ACCOUNT_MENU_STORE' )}
                      </span>
                    )}
                  </NavLink>
                }
                <NavLink
                  to={ localePath( '/support' ) }
                  prefetch='intent'
                  end={ true }>
                  {( { isActive } ) => (
                    <span className={ classNames(
                      'hover:text-white',
                      isActive ? 'text-white' : undefined
                    ) }>
                      { t( 'HEADER_MENU_SUPPORT' )}
                    </span>
                  )}
                </NavLink>
                { !isLoggedIn &&
                  <NavLink
                    to={ localePath( '/signin' ) }
                    prefetch='intent'
                    end={ true }>
                    {( { isActive } ) => (
                      <span className={ classNames(
                        'hover:text-white',
                        isActive ? 'text-white' : undefined
                      ) }>
                        { t( 'HEADER_MENU_SIGNIN' )}
                      </span>
                    )}
                  </NavLink>
                }
                { isLoggedIn &&
                  <Link
                    to={ localePath( '/signout' ) }
                    reloadDocument>
                    <span className={ classNames(
                      'hover:text-white'
                    ) }>
                      { t( 'HEADER_MENU_SIGNOUT' )}
                    </span>
                  </Link>
                }
              </div>
            </div>
            <div>
              <Button
                variant={ ButtonVariant.Primary }
                size={ ButtonSize.Small }
                className={ 'text-base' }
                to={ localePath( '/redeem' ) }
                prefetch='intent'
              >
                { t( 'BUTTON_REDEEM_LABEL' )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

